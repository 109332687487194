export const USERS = [{
        id: "1",
        name: "Jean"
    }, {
        id: "2",
        name: "Sophie"
    }, {
        id: "3",
        name: "Chris"
    }, {
        id: "4",
        name: "Lucas"
    }, {
        id: "5",
        name: "Vincent"
    }];
