import React from "react";
import PropTypes from "prop-types";
import { Tabs } from "antd";
import { REQUESTS } from "./api";

const {TabPane} = Tabs;

const Help = ({ keys }) => {
    return (<Tabs tabPosition = {"left"} >
        { keys.map((key, index) => {
                    const item = REQUESTS[key];
                    return <TabPane tab={item.title} key={index}>
                        <table>
                            <tr><td>Url :</td><td>{item.url}</td></tr>
                            <tr><td>Méthode :</td><td>{item.method}</td></tr>
                            { item.request && <tr><td>Requête :</td><td><pre>{JSON.stringify(item.request, null, 2) }</pre></td></tr> }
                            <tr><td>Réponse :</td><td><pre>{JSON.stringify(item.response, null, 2) }</pre></td></tr>
                        </table>
                    </TabPane>;
                }) }
    </Tabs>);
};

Help.propTypes = {
    keys: PropTypes.array.isRequired
};

Help.defaultProps = {
    keys: []
};

export default Help;
