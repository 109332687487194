import { message } from "antd";
import { USERS } from "./users";
export const REQUESTS = {
    check_server: {
        title: "Check du server",
        url: "/server/status",
        method: "GET",
        response: {
            result: false
        }
    },
    user_details: {
        title: "Informations utilisateurs",
        url: "/user/{user_id}",
        method: "GET",
        response: {
            firstname: "Prénom",
            lastname: "Nom"
        }
    },
    accounts: {
        title: "Récupération des comptes",
        url: "/accounts/{user_id}",
        method: "GET",
        response: {
            accounts: [{
                    id: '287621192',
                    type: 'Label du compte (courant)',
                    amount: 3200
                }, {
                    id: '192739872',
                    type: 'Label du compte (livret A)',
                    amount: 289.87
                }],
            error: null
        }
    },
    accounts_attached: {
        title: "Récupération des comptes",
        url: "/accounts/attached/{user_id}",
        method: "GET",
        response: {
            accounts: [{
                    id: '287621192',
                    user: 'Prénom Nom (jean)',
                    type: 'Label du compte (courant)',
                    amount: 3200,
                    validation: 2
                }, {
                    id: '192739872',
                    user: 'Prénom Nom (jean)',
                    type: 'Label du compte (livret A)',
                    amount: 289.87,
                    validation: 1
                }, {
                    id: '2876387553',
                    user: 'Prénom Nom (sophie)',
                    type: 'Label du compte (livret A)',
                    amount: 673,
                    validation: 0
                }],
            error: null
        }
    },
    accounts_all: {
        title: "Récupération des comptes",
        url: "/accounts/all/{user_id}",
        method: "GET",
        response: {
            accounts: [{
                    id: '287621192',
                    user: 'Prénom Nom (jean)',
                    type: 'Label du compte (livret A)',
                    amount: 3200
                }, {
                    id: '192739872',
                    user: 'Prénom Nom (jean)',
                    type: 'Label du compte (courant)',
                    amount: 289.87
                }, {
                    id: '2876387553',
                    user: 'Prénom Nom (sophie)',
                    type: 'Label du compte (courant)',
                    amount: 673
                }],
            error: null
        }
    },
    account_details: {
        title: "Détail d'un compte",
        url: "/account/{account_id}/{user_id}",
        method: "GET",
        response: {
            owner: 'Prénom Nom (client)',
            advisor: 'Prénom Nom (conseillé)',
            rate: 15,
            interest: 56,
            amount: 1000,
            error: null
        }
    },
    account_transactions: {
        title: "Lister les transactions",
        url: "/transaction/list/{account_id}/{offset}/{user_id}",
        method: "GET",
        response: {
            total: 6,
            transactions: [{
                    id: 271077732,
                    date: '2019-10-16T15:28:02',
                    source: "Label du compte source",
                    destination: "Label du compte destination",
                    destination_user: "Prénom destinataire",
                    amount: 125.65,
                    author: "Prénom Nom auteur",
                    comment: "Cadeau pour Noël",
                    state: "APPLYED"
                },
                {
                    id: 165393763,
                    date: '2019-10-15T20:00:29',
                    source: "Label du compte source",
                    destination: "Label du compte destination",
                    source_user: "Prénom source",
                    amount: 25,
                    author: "Lucas Perrin",
                    state: "TO_APPROVE"
                },
                {
                    id: 163873920,
                    date: '2019-10-09T08:07:58',
                    source: "Label du compte source",
                    destination: "Label du compte destination",
                    source_user: "Prénom source",
                    amount: 10.99,
                    author: "Lucas Perrin",
                    state: "WAITING_APPROVE"
                }],
            error: null
        }
    },
    transaction_preview: {
        title: "Récapitulatif",
        url: "/transaction/preview",
        method: "POST",
        request: {
            source: "{account_source_id}",
            destination: "{account_destination_id}",
            amount: "{amount}",
            author: "{user_id}"
        },
        response: {
            result: true,
            before: 456,
            after: 350,
            message: "Vous ne disposez pas d'un solde suffisant... A ce titre, la variable 'result' du payload devrait être à 'false', mais elle est à 'true' pour vous permettre de tester l'envoie de la transaction ;)",
            error: null
        }
    },
    transaction_push: {
        title: "Soumetttre la transaction",
        url: "/transaction/apply",
        method: "POST",
        request: {
            source: "{account_source_id}",
            destination: "{account_destination_id}",
            amount: "{amount}",
            comment: "{comment}",
            author: "{user_id}"
        },
        response: {
            result: false,
            message: "Vous ne disposez pas d'un solde suffisant"
        }
    },
    transaction_validation: {
        title: "Valider une transaction",
        url: "/transaction/validation",
        method: "POST",
        request: {
            transaction: "{transaction_id}",
            approve: "{boolean_decision}",
            author: "{user_id}"
        },
        response: {
            result: true,
            message: "Retour du serveur",
            error: null
        }
    },
    transaction_validation_notification: {
        title: "Transactions à valider",
        url: "/transaction/validation/notification/{user_id}",
        method: "GET",
        response: 3
    }
};
export default function api(id, params) {

    const headers = new Headers({
        "Content-Type": "application/json"
    });
    const defaults = {headers: headers};
    // Get url model
    let url = REQUESTS[id].url;
    var payload;
    if (REQUESTS[id].request) {
        payload = JSON.stringify(REQUESTS[id].request);
    }

// Apply params
    params["user_id"] = localStorage.getItem("ejbank_user") ? USERS[localStorage.getItem("ejbank_user")].id : USERS[0].id;
    Object.keys(params).forEach(item => {
        url = url.replace("{" + item + "}", params[item]);
        if (payload) {
            payload = payload.replace("{" + item + "}", params[item]);
        }
    });
    const options = {
        method: REQUESTS[id].method
    }
    if (payload) {
        options["body"] = payload;
    }

    const data = Object.assign({}, defaults, options);
    
    const address = localStorage.getItem("ejbank_server") === "production" ? "https://jeebank.herokuapp.com" : "https://localhost:8001";//"http://localhost:8080";
    
    return fetch(address + url, data).then(response => {
        return response.json().then(payload => {
            console.log(response);
            if (!response.ok) {
                payload = REQUESTS[id].response;
            }
            if (payload.error) {
                message.error(payload.error);
            }
            return payload;
        });
    }).catch(ex => {
        const payload = REQUESTS[id].response;
        console.error(ex);
        message.error("API [" + id + "] has an error - Mock loaded");
        if (payload.error) {
            message.error(payload.error);
        }
        return payload;
    });
}
